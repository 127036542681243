<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.10693 7.90967L5.43638 5.68421C5.0396 5.35356 4.84121 5.18823 4.69858 4.98557C4.57221 4.806 4.47836 4.60563 4.42132 4.39359C4.35693 4.15428 4.35693 3.89604 4.35693 3.37954V1.65967M8.10693 7.90967L10.7775 5.68421C11.1743 5.35356 11.3727 5.18823 11.5153 4.98557C11.6417 4.806 11.7355 4.60563 11.7925 4.39359C11.8569 4.15428 11.8569 3.89604 11.8569 3.37954V1.65967M8.10693 7.90967L5.43638 10.1351C5.0396 10.4658 4.84121 10.6311 4.69858 10.8338C4.57221 11.0133 4.47836 11.2137 4.42132 11.4257C4.35693 11.6651 4.35693 11.9233 4.35693 12.4398V14.1597M8.10693 7.90967L10.7775 10.1351C11.1743 10.4658 11.3727 10.6311 11.5153 10.8338C11.6417 11.0133 11.7355 11.2137 11.7925 11.4257C11.8569 11.6651 11.8569 11.9233 11.8569 12.4398V14.1597M3.10693 1.65967H13.1069M3.10693 14.1597H13.1069" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconClockSolid'
}
</script>
